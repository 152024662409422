<template>
  <div class="container">
    <!-- <Password v-if="!isAuthenticated" /> -->
    <!-- <PeaceMakerIsComingTrailer v-else /> -->
    <PeaceMakerIsComingTrailer />
  </div>
</template>

<script>
// import Password from "./components/Password.vue";
import PeaceMakerIsComingTrailer from "./components/PeaceMakerIsComingTrailer.vue";
export default {
  name: "App",
  components: {
    // Password,
    PeaceMakerIsComingTrailer,
  },
  data() {
    return {
      isAuthenticated: false,
      videoUrl: "",
    };
  },
  mounted() {},
};
</script>

<style lang="scss">
@font-face {
  font-family: "handmade-gothic";
  src: local("handmade-gothic"),
    url("./assets/handmade-gothic.otf") format("opentype");
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  margin: 0;
  background-color: #6c1310;
  min-height: 100vh;
}
</style>
